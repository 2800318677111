import React from 'react';
import { Element } from 'react-scroll';
import HeroSection from './components/HeroSection';
import FeatureSection from './components/FeatureSection';
import FeatureSectionTwo from './components/FeatureSectionTwo';
// import RecentWorkSection from './components/RecentWorkSection';
import ScopeSection from './components/ScopeSection';
import PricingSection from './components/PricingSection';
import FrequentlyAskedSection from './components/FrequentlyAskedSection';
import FooterSection from './components/FooterSection';
import CallToActionSection from './components/CallToActionSection';





function LandingPage() {
  return (
    <div>
      <HeroSection />
      
      <Element name="benefits" className="benefits">
        <FeatureSection />
      </Element>
      
      <FeatureSectionTwo />
 

      <Element name="recentWork" className="recentWork">
        <ScopeSection />
      </Element>

{/* 
      <Element name="examples" className="examples">
      <RecentWorkSection />
      </Element> */}

        


      <Element name="pricing" className="pricing">
        <PricingSection />
      </Element>
      
      <Element name="faqs" className="faqs">
        <FrequentlyAskedSection />
      </Element>

      <Element name="login" className="login">
        <CallToActionSection />
      </Element>
      
      <FooterSection />



    </div>
  );
}

export default LandingPage;
