import { CheckIcon } from "@heroicons/react/20/solid";
import { Link } from "react-scroll";

// Define pricing tiers with their details
const tiers = [
  {
    name: "Startup Plan",
    id: "tier-startup",
    href: "login", // Update this with your actual purchase link
    priceMonthly: "$7,995",
    description:
      "Ideal for early-stage companies needing foundational data engineering support.",
    features: [
      "One active project at a time",
      "Average 48-hour feature delivery",
      "Unlimited user access",
      "Advanced analytics capabilities",
      "Simple credit card payments",
      "Pause or cancel your subscription anytime",
    ],
    call: "Sign Up Now",
    featured: true,
  },
  {
    name: "Schedule a Consultation",
    id: "tier-consultation",
    href: "login", // Update with your Calendly link
    priceMonthly: "Free",
    description:
      "Discover more about our services and how we can assist in achieving your data engineering goals.",
    features: [],
    call: "Schedule Now",
    featured: false,
  },
  {
    name: "Referral Program",
    id: "tier-referral",
    href: "login", // Update this with your referral program link
    priceMonthly: "Earn 5%",
    description:
      "Refer new clients and receive a 5% ongoing commission for every successful referral.",
    features: [],
    call: "Become a Referrer",
    featured: false,
  },
];

// React component for the pricing section
export default function Example() {
  return (
    <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
      {/* Pricing section content */}
      <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
        {/* Pricing section title */}
        <h2 className="text-base font-semibold leading-7 text-indigo-600">
          Pricing
        </h2>
        {/* Pricing section subtitle */}
        <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          One Subscription for all your needs
        </p>
      </div>
      {/* Pricing section description */}
      <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
        Flexible options that grow with your business. Pause or cancel at any
        time.
      </p>

      {/* Grid layout for pricing tiers */}
      <div className="mx-auto mt-16 grid max-w-4xl grid-cols-1 lg:grid-cols-2 gap-y-6 sm:mt-20 sm:gap-y-0">
        {/* Featured card */}
        {tiers
          .filter((tier) => tier.featured)
          .map((tier) => (
            <div
              key={tier.id}
              className="relative bg-white shadow-2xl rounded-3xl p-8 ring-1 ring-gray-900/10 max-w-[400px] mx-auto"
            >
              {/* Tier name */}
              <h3
                id={tier.id}
                className="text-base font-semibold leading-7 text-indigo-600"
              >
                {tier.name}
              </h3>
              {/* Tier price */}
              <p className="mt-4 flex items-baseline gap-x-2">
                <span className="text-5xl font-bold tracking-tight text-gray-900">
                  {tier.priceMonthly}
                </span>
                <span className="text-base text-gray-500">/month</span>
              </p>
              {/* Tier description */}
              <p className="mt-6 text-base leading-7 text-gray-600">
                {tier.description}
              </p>
              {/* List of tier features */}
              <ul
                role="list"
                className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-indigo-600"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
              {/* Call to action button */}
              <Link
                to={tier.href}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="rounded-md bg-indigo-600 text-white shadow hover:bg-indigo-500 mt-8 block rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:mt-10"
              >
                {tier.call}
              </Link>
            </div>
          ))}

        {/* Non-featured cards */}
        <div className="lg:col-span-1 space-y-6 mx-auto">
          {tiers
            .filter((tier) => !tier.featured)
            .map((tier) => (
              <div
                key={tier.id}
                className="bg-white/60 rounded-3xl p-8 ring-1 ring-gray-900/10 max-w-[400px]"
              >
                {/* Tier name */}
                <h3
                  id={tier.id}
                  className="text-base font-semibold leading-7 text-indigo-600"
                >
                  {tier.name}
                </h3>
                {/* Tier price */}
                <p className="mt-4 flex items-baseline gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">
                    {tier.priceMonthly}
                  </span>
                  <span className="text-base text-gray-500"></span>
                </p>
                {/* Tier description */}
                <p className="mt-6 text-base leading-7 text-gray-600">
                  {tier.description}
                </p>
                {/* List of tier features */}
                <ul
                  role="list"
                  className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
                >
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
                {/* Call to action button */}
                <Link
                  to={tier.href}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 mt-8 block rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:mt-10"
                >
                  {tier.call}
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
