import React from "react";
import LandingPage from "./LandingPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/privacy" element={<PrivacyPage />} />
          <Route exact path="/terms" element={<TermsPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
