import React from 'react';
import { DevicePhoneMobileIcon, CloudArrowUpIcon, CogIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Versatile Development',
    description:
      'From data pipelines to machine learning models, our team uses technologies like Apache Spark, Hadoop, and more to build scalable, efficient solutions.',
    icon: DevicePhoneMobileIcon,
  },
  {
    name: 'Seamless Integration & Deployment',
    description: 'We ensure your data solutions integrate smoothly with your existing systems and manage deployment from start to finish, eliminating any hassle.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Ongoing Optimization',
    description: 'Stay ahead with continuous maintenance and optimization of your data systems, keeping them up-to-date and performing at their best.',
    icon: CogIcon,
  },
]

export default function Example() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
          <div className="px-6 md:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Build Faster</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Comprehensive Data Engineering Services</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Our expert team handles all aspects of data engineering, from initial planning and design to development, integration, and maintenance.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src="./buildFaster.png"
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  )
}
