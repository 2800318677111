import React from 'react';

export default function Example() {
  return (
    <div className="bg-black py-32">
      <div className="mx-auto max-w-2xl text-center">
      <form className="space-y-6" action="#" method="POST">
        <div >
        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-5xl mb-8">
              Let's get connected
            </h2>
            <p className="mx-auto mt-8 max-w-xl text-xl leading-8 text-white mb-8">
              Tell us about your business
            </p>
        </div>
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-white text-left">
            Name
          </label>
          <div className="mt-1">
            <input
              id="name"
              name="name"
              type="text"
              autoComplete="name"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium text-white text-left">
            Email
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div>
          <label htmlFor="company" className="block text-sm font-medium text-white text-left">
            Company
          </label>
          <div className="mt-1">
            <input
              id="company"
              name="company"
              type="text"
              autoComplete="organization"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div>
          <label htmlFor="message" className="block text-sm font-medium text-white text-left">
            Message
          </label>
          <div className="mt-1">
            <textarea
              id="message"
              name="message"
              rows={4}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              defaultValue={''}
            />
          </div>
        </div>

        <div className="mt-10 flex items-center justify-center gap-x-8">
          <button
            type="submit"
            className="rounded-md bg-white px-7 py-5 text-2xl font-semibold text-black shadow-sm hover:bg-yellow-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"

>
            Get started
          </button>
        </div>
      </form>
      </div>
    </div>
  );
}
