import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "What makes hiring your agency better than employing a full-time developer?",
    answer: "Choosing our agency over hiring a full-time developer offers flexibility and access to a diverse set of skills. Our team includes experts in various data engineering fields, ensuring comprehensive coverage for all your needs at a fraction of the cost of a full-time hire."
  },
  {
    question: "How many projects can I run concurrently?",
    answer: "The number of concurrent projects depends on your chosen subscription plan. Each plan is designed to meet different levels of demand, from single projects for smaller teams to multiple concurrent projects for larger organizations."
  },
  {
    question: "What's the typical timeline for data engineering project completion?",
    answer: "Project timelines vary based on complexity and scope. Simple projects can be completed in a few weeks, while more complex solutions might take longer. We prioritize efficiency and quality, ensuring timely delivery without compromising on standards."
  },
  {
    question: "Can you tell me about your development team?",
    answer: "Our team comprises seasoned data engineers with expertise in big data technologies, machine learning, cloud platforms, and more. We bring a wealth of experience from various industries, ensuring a rich knowledge base for innovative solutions."
  },
  {
    question: "How does the subscription pause feature work?",
    answer: "Our pause feature allows you to temporarily halt your subscription without cancelling it, providing flexibility for when your project needs change. You can easily resume the service when new projects arise."
  },
  {
    question: "What technologies and frameworks are your specialties?",
    answer: "We specialize in a wide array of technologies and frameworks, including but not limited to Apache Spark, Hadoop, Kafka for data processing, Python and Scala for programming, and AWS, Google Cloud, and Azure for cloud services."
  },
  {
    question: "How do I initiate a new data engineering project?",
    answer: "Initiating a new project is simple. Reach out to us with your project details via our website, email, or direct call. We'll set up an initial consultation to understand your requirements and kickstart the project."
  },
  {
    question: "What happens if I'm not satisfied with the outcome?",
    answer: "Your satisfaction is our priority. If the initial deliverables do not meet your expectations, we will work closely with you to make the necessary revisions and adjustments until you are satisfied with the result."
  },
  {
    question: "Is your service suitable for a one-off project?",
    answer: "Yes, our service is flexible enough to accommodate one-off projects. You can choose a plan that suits short-term needs and pause or end your subscription once your project is complete."
  },
  {
    question: "What's your policy on refunds for unsatisfactory service?",
    answer: "While we strive for excellence, we understand the importance of customer satisfaction. If you're not satisfied with our service, please contact us to discuss potential refunds or other ways we can rectify the situation."
  },
];

export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-4xl font-bold leading-10 tracking-tight text-gray-900 text-center">FAQs</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
