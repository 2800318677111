import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries




// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDlws6ul44OI2_iTs0O6HP-4Jt5uoMaTzA",
  authDomain: "agency-bdd84.firebaseapp.com",
  projectId: "agency-bdd84",
  storageBucket: "agency-bdd84.appspot.com",
  messagingSenderId: "1081311496264",
  appId: "1:1081311496264:web:1607f50347b3e1aafb307d",
  measurementId: "G-S93LW3K3TL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

