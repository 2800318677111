import React from 'react';
import { BoltIcon, CreditCardIcon, StarIcon, SparklesIcon, ClipboardDocumentListIcon, ArrowsUpDownIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Rapid Turnaround',
    description: 'Expect feature delivery in days, not weeks, accelerating your project timelines.',
    icon: <BoltIcon className="h-24 w-24 text-yellow-500" />,
  },
  {
    name: 'Transparent Pricing',
    description: 'Enjoy predictable budgeting with our fixed monthly rates, free from hidden fees.',
    icon: <CreditCardIcon className="h-24 w-24 text-blue-500" />,
  },
  {
    name: 'Top-Tier Quality',
    description: 'Benefit from our commitment to excellence with every project delivered to the highest standards.',
    icon: <StarIcon className="h-24 w-24 text-yellow-300" />,
  },
  {
    name: 'Scalability & Flexibility',
    description: 'Adjust your service level as your business evolves. Pause or cancel your subscription at any time.',
    icon: <ArrowsUpDownIcon className="h-24 w-24 text-indigo-500" />,
  },
  {
    name: 'Custom Solutions',
    description: 'Each project is tailored to your unique requirements, ensuring you receive personalized, effective data engineering solutions.',
    icon: <SparklesIcon className="h-24 w-24 text-purple-500" />,
  },
  {
    name: 'Unlimited Requests',
    description: "Submit as many tasks as you need - we're equipped to handle multiple projects simultaneously, ensuring timely delivery.",
    icon: <ClipboardDocumentListIcon className="h-24 w-24 text-teal-500" />,
  },
]

export default function Features() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto text-center lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Exclusive Benefits</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Our expert team ensures your data engineering projects benefit from rapid delivery, exceptional quality, and unparalleled flexibility.
          </p>
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {features.map((feature) => (
            <div key={feature.name} className='flex flex-col items-center'>
              <div className='mb-4'>{feature.icon}</div>
              <dt className="font-semibold text-gray-900 text-center">{feature.name}</dt>
              <dd className="mt-1 text-gray-600">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
