const navigation = {
  main: [
    { name: 'Privacy', href: 'privacy' },
    { name: 'Terms', href: 'terms' },
    { name: 'Contact', href: "mailto:contact@dataengineeragency.com?" },
  ],
}

export default function Example() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-black">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="pb-6">
              <a href={item.href} className="text-sm leading-6 text-white hover:text-gray-400">
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <p className="mt-10 text-center text-xs leading-5 text-white">
          &copy; {currentYear} Data Engineer Agency
        </p>
      </div>
    </footer>
  )
}
